import React from "react"
import { Link } from 'gatsby';

const ProjectFooter = ({ next, prev }) => {

    return (
        <section className="section project--nav">
            <div className="next--project">
                {prev != null && (
                    <>
                    <p className="nav--direction">Previous</p>
                    <div className="related-item w-full flex justify-start relative mt-2">
                        <Link 
                            to={`/projects/${prev.slug && prev.slug.current}`} 
                            className={`${prev.slug ? 'available-link' : 'not-available-link' } someday-serif`}>
                                {prev.title && <p className="nav--title-footer">{prev.title}</p>}
                        </Link>
                    </div>
                    </>
                )}
            </div>
            <div className="prev--project md:text-right mt-row md:mt-0">
                {next != null && (
                    <>
                    <p className="nav--direction">Next</p>
                    <div className="related-item w-full flex justify-start md:justify-end relative mt-2">
                        <Link 
                            to={`/projects/${next.slug && next.slug.current}`} 
                            className={`${next.slug ? 'available-link' : 'not-available-link' } someday-serif`}>
                                {next.title && <p className="md:text-right nav--title-footer">{next.title}</p>}
                        </Link>
                    </div>
                    </>
                )}
            </div>
        </section>
    )
}

export default ProjectFooter
