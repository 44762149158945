import React from 'react';
// import { QueryFragments } from '../GraphQl/queryFragments';
import { PageMeta } from "../components/pageMeta"
import { graphql } from "gatsby"
import BlockZone from "~blockZone"
import ProjectFooter from '../components/postFooter';

import ProjectHeader from '../components/blockZone/blocks/projectHeader';



const ProjectPage = ({ pageContext, data: { page } }) => {

  const { seo, pageBlocks } = page || {}

  return (
    <div>
      <ProjectHeader project={page} />
      <PageMeta {...seo} />
      {pageBlocks && <BlockZone {...pageBlocks} />}
      <ProjectFooter {...pageContext} />
    </div>
  )
}

export default ProjectPage;


export const pageQuery = graphql`
  query projectPostQuery($slug: String!) {
    page: sanityProjectPost(slug: { current: { eq: $slug } }) {
    seo {
        ...MetaCardFields
    }
    title
    roles
    description
    metatext
    layout
    hero_type
    hero_image{
      asset {
          gatsbyImageData(fit: FILL, placeholder: DOMINANT_COLOR)         
        }
      }
    hero_video{
      asset {
        url
        title
      }
    }
    pageBlocks {
        ...BlockZone
      }
    }
  }
`;