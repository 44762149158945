import React from "react"
// import Image from "gatsby-plugin-sanity-image"

import { BlockWrapper } from "../blockWrapper"
import { PortableTextBlock } from "../../sanity/portableTextBlock"
import { textBlockStyle } from "../../../hooks"

import { GatsbyImage } from 'gatsby-plugin-image'
import { VideoModule } from "../../modules"


const ProjectHeader = ({ project, _rawText, image, layout, metaText, blockStyle, textStyle }) => {


    let blockConfig = {
        blockVerticalBottomPadding: '',
        blockVerticalTopPadding: 'large'
    }

    const isHero = true;
    const heroImage = isHero ? 'hero-image' : 'page-image'

    console.log('ProjectHeader', project)

    return (

        <section className={`flex flex-col md:flex-row${project?.layout == 'left' ? '-reverse' : ''} md:h-screen`}>
        

            {/* Image - left */}
            <div className={`md:w-3/6  top-0 right-0 md:h-screen`}>
                {project?.hero_type == 'image' &&
                    <img
                        src={project?.hero_image?.asset?.gatsbyImageData.images.fallback.src}
                        style={{
                            objectFit: 'cover',
                            top: '0px',
                            width: '100%'
                        }}
                        className="md:h-screen"
                    />
                }
                {project?.hero_type == "video" &&
                    <VideoModule cover={true} asset={project?.hero_video?.asset} />
                }
            </div>

            {/* Text - Right */}
            {project?.title &&
                <div className="w-full p-5 md:p-0 md:w-3/6 md:grid md:grid-cols-6 md:gap-3 justify-center text-image--text">
                    <div className="text--inner flex flex-col justify-between col-span-full  ">
                        <div className="md:grid md:grid-cols-6 md:gap-3">
                            <div className="md:col-start-2 md:col-span-3">
                            <h1 className=" md:pt-extra-large h1 prose type-project_title pb-7 ">{project?.title}</h1>
                            {project?.roles &&

                                <ul>
                                    {project?.roles.map(role => (
                                        <li className="type-project_role">{role}</li>
                                    ))}
                                </ul>

                            }

                            <p className="type-project_role mt-12">{project?.description}</p>
                            </div>
                        </div>
                        <div className="md:grid md:grid-cols-6 md:gap-3">
                            <p className="text-left font-heading pb-4 pt-8 md:col-start-2 md:col-span-full">{project?.metatext}</p>
                        </div>
                        
                    </div>
                    

                </div>
            }

        </section>
    )

}

export default ProjectHeader;
